import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import DocumentationPageLayout from "@src/components/DocumentationPageLayout";
import window from "global/window";

function OneTrustCookieTable() {
  const [isOneTrustReady, setIsOneTrustReady] = useState(false);

  useEffect(() => {
    /* eslint-disable-next-line prefer-const */
    let intervalId;

    function tryInitializeOneTrust() {
      if (typeof window !== "undefined" && window.OneTrust && window.OneTrust.initializeCookiePolicyHtml) {
        window.OneTrust.initializeCookiePolicyHtml();
        setIsOneTrustReady(true);
        clearInterval(intervalId);
      }
    }

    tryInitializeOneTrust();
    intervalId = setInterval(tryInitializeOneTrust, 500);

    return () => clearInterval(intervalId);
  }, []);
  if (!isOneTrustReady) {
    return <div>Loading cookie list…</div>;
  }
  return <div id="ot-sdk-cookie-policy" />;
}

const CookiesPolicyContent = () => {
  const LinkButton = styled("button")`
    border: 0;
    background: transparent;
    font-weight: bold;
    color: #00bcf2;
    cursor: pointer;
    padding: 0;
    &:hover {
      text-decoration: underline;
    }
  `;
  return (
    <div className="col-sm-12 col-md-9">
      <div className="page-section">
        <h1 id="online-tracking-technologies-and-advertising-on-classdojo-com">
          ONLINE TRACKING TECHNOLOGIES AND ADVERTISING ON CLASSDOJO.COM
        </h1>
        <p>
          <strong>Last Updated: March 14, 2025</strong>
        </p>
        <p>
          ClassDojo offers a range of features that use technologies like cookies, pixel tags (&quot;pixels&quot;),
          device or other identifiers, and local storage (collectively, “Online Tracking Technologies”) to provide a
          seamless and safe experience on ClassDojo.{" "}
          <strong>
            <em>
              Some of the technologies described below are used solely on our informational website located at{" "}
              <a href="https://www.classdojo.com">https://www.classdojo.com</a> (the “ClassDojo Website”) and others are
              used only in logged-in areas of the Service, such as through the ClassDojo Platform or ClassDojo App.
            </em>
          </strong>{" "}
          Any terms not defined here shall have the meaning set forth in the ClassDojo{" "}
          <a href="https://www.classdojo.com/privacy/">Privacy Policy</a>.{" "}
        </p>
        <p>
          First and foremost, we do not use or disclose information collected through Online Tracking Technology for{" "}
          <a href="https://classdojo.zendesk.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_6fb13f0c-b840-412f-9956-2a519005384d">
            third-party advertising
          </a>{" "}
          purposes (including{" "}
          <a href="https://classdojo.zendesk.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_031173d7-11db-40b2-ade4-9e3e8c3f4d54">
            behaviorally targeting advertising
          </a>
          ) to students or children on the Service. We will NOT use advertising cookies in areas where students or
          children are logged in to the Services or on the ClassDojo Website where a student or child user is directed
          to login to the dedicated child or student login page.
        </p>
        <p>
          As described in more detail below, we use these technologies in many ways so that ClassDojo works, like for
          security purposes, to provide certain helpful features, and also to understand and measure how ClassDojo is
          used by teachers, parents, and students. Third parties that you interact with as you use the Service may also
          use these technologies for a variety of purposes.
        </p>
        <p>
          This policy does not apply to Dojo Tutor. To see Dojo Tutor’s Online Tracking Policy, visit{" "}
          <a href="https://tutor.classdojo.com/#/cookies-policy">here</a>.{" "}
        </p>
        <p>
          For a full list of all the different tracking technologies we use, please see{" "}
          <a href="https://www.classdojo.com/cookies-policy/?oneTrust=true#FullListCookies">
            <strong>here.</strong>
          </a>{" "}
          <strong>
            <em>
              Check back here from time to time to get the latest information about these technologies and how they are
              used on ClassDojo.
            </em>
          </strong>
        </p>
        <p>
          Your browser or device may offer settings related to these technologies - visit your browser or device&#39;s
          help material to learn about your options. We may, however, not recognize or respond to browser or device
          signals around tracking, and changing these settings may impact your ability to use some of the great features
          on ClassDojo.
        </p>
        <p>Below we provide more information about Online Tracking Technologies and how they work.</p>
        <hr />
        <h2 id="quick-reference-">Quick Reference:</h2>
        <ul>
          <li>
            <a href="#what-are-some-of-these-online-tracking-technologies">
              What are some of these Online Tracking Technologies?
            </a>
          </li>
          <li>
            <a href="#how-do-we-use-online-tracking-technologies-when-you-use-a-mobile-device">
              How do we use Online Tracking Technologies when you use a mobile device?
            </a>
          </li>
          <li>
            <a href="#how-can-i-manage-these-online-tracking-technologies">
              How can I manage these Online Tracking Technologies?
            </a>
          </li>
          <li>
            <a href="#why-do-we-use-online-tracking-technologies">Why do we use Online Tracking Technologies?</a>
          </li>
          <li>
            <a href="#how-do-third-parties-use-online-tracking-technologies-on-our-service-and-the-classdojo-website?">
              How do third parties use Online Tracking Technologies on our Service and the ClassDojo Website?
            </a>
          </li>
          <li>
            <a href="#how-can-you-control-classdojo’s-use-of-online-tracking-technologies-to-show-you-ads?">
              How can you control ClassDojo’s use of Online Tracking Technologies to show you ads?
            </a>
          </li>
          <li>
            <a href="#online-tracking-technologies-from-unregistered-users">
              Online Tracking Technologies from unregistered users
            </a>
          </li>
          <li>
            <a href="#detailed-list-of-online-tracking-technologies-in-the-services-and-classdojo-website">
              Detailed list of Online Tracking Technologies in the Services and ClassDojo Website
            </a>
          </li>
        </ul>
        <hr />
        <h2 id="what-are-some-of-these-online-tracking-technologies">
          What are some of these Online Tracking Technologies?
        </h2>
        <ul>
          <li>
            <p>
              <a href="https://help.classdojo.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_3cee3e5e-c072-4fc2-821d-84258d56c2c4">
                <strong>Cookies and other tracking technologies</strong>
              </a>{" "}
            </p>
          </li>
          <li>
            <p>
              <a href="https://classdojo.zendesk.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_b865d3eb-6e41-467c-9b53-7f2c9bd73562">
                <strong>Pixel tags</strong>
              </a>{" "}
            </p>
          </li>
          <li>
            <p>
              <a href="https://classdojo.zendesk.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_efdb3ae6-8829-4e77-a8e0-b47c865fbe1b">
                <strong>Local storage</strong>
              </a>{" "}
            </p>
          </li>
        </ul>
        <hr />
        <h2 id="how-do-we-use-online-tracking-technologies-when-you-use-a-mobile-device">
          How do we use Online Tracking Technologies when you use a mobile device?
        </h2>
        <p>
          Many mobile devices act just like computers, such as smartphones that support full-featured browsers. For
          those devices, we use Online Tracking Technologies in ways that are similar to when you are accessing the web
          from a computer. Other devices use different technologies such as local storage on a device, Software
          Development Kits (SDKs), or Advanced Programming Interfaces (APIs) to facilitate the same types of processes
          we use Cookies for, as described below. As on the web, we also may use similar technologies to store and
          retrieve an identifier or other information from or on your device. For example, with your permission, we may
          use an advertising identifier such as the Identifier Advertiser (“IDFA”) on iOS devices and the Advertising ID
          on Android devices where cookies are not available.{" "}
        </p>
        <p>
          With your permission, we may also obtain or receive information about your use of your mobile device and our
          Service. We describe how we use these technologies and the information we received from them in our{" "}
          <a href="https://www.classdojo.com/privacy/">
            <strong>Privacy Policy</strong>
          </a>
          .
        </p>
        <h2 id="how-can-i-manage-these-online-tracking-technologies">
          How can I manage these Online Tracking Technologies?
        </h2>
        <ul>
          <li>
            <strong>
              <em>Browser Cookie Controls:</em>
            </strong>{" "}
            Your browser or device may offer settings related to these technologies - visit your browser or device&#39;s
            help menu to learn about your options (browsers for mobile devices may not offer visibility). For example,
            you may set your browser to refuse all cookies or to indicate when a cookie is being sent. However, changing
            these settings may impact your use of some features or services on our Service or the ClassDojo Website as
            they may not function properly without cookies.
          </li>
        </ul>
        <p>
          While some internet browsers offer a “do not track” or “DNT” option that lets you tell websites that you do
          not want to have your online activities tracked, these features are not yet uniform and there is no common
          standard adopted by industry groups, technology companies, or regulators. In addition, the industry working
          group (W3C Tracking Protection) to develop the DNT standard is now{" "}
          <a href="https://www.w3.org/2011/tracking-protection/">closed</a>, so ClassDojo does not respond to these DNT
          signals. There are different (from DNT) opt-out preference signals set forth under the CCPA (“Opt-Out
          Preference Signals”) also called a Global Privacy Control (GPC) Signal. ClassDojo will respond to Opt-out
          Preference or Global Privacy Control Signals. This signal will apply to the browser. A consumer can use an
          Opt-Out Preference Signal by following the instructions <a href="https://globalprivacycontrol.org/">here</a>.
          ClassDojo takes privacy and meaningful choice seriously and will make efforts to continue to monitor
          developments around DNT browser technology and the implementation of a standard. For more information on “do
          not track,” please visit <a href="http://www.allaboutdnt.org">www.allaboutdnt.org</a>.{" "}
        </p>
        <ul>
          <li>
            <p>
              <strong>
                <em>ClassDojo Cookie Preference Center:</em>
              </strong>{" "}
              You will also have certain options for management of Online Tracking Technologies as set forth in the
              applicable jurisdiction-specific{" "}
              <LinkButton onClick={window && window.OneTrust ? window.OneTrust.ToggleInfoDisplay : undefined}>
                ClassDojo Cookie Settings Preference Center
              </LinkButton>
              .
            </p>
          </li>
          <li>
            <p>
              <strong>
                <em>Flash Cookies:</em>
              </strong>{" "}
              Cookie management tools provided by your browser will not remove Flash cookies. Learn how to{" "}
              <a href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html">
                manage privacy and storage settings for Flash cookies
              </a>
              . If you disable Flash cookies, you won’t have access to many features that make your guest experience
              more efficient and some of our Services or the ClassDojo Website will not function properly.{" "}
            </p>
          </li>
          <li>
            <p>
              <strong>
                <em>Mobile:</em>
              </strong>{" "}
              Please note that the choices described above regarding browser-based opt-outs may not apply to mobile
              applications. Your mobile device operating system may offer settings that enable you to make choices about
              the collection of mobile app information for targeted advertising (such as{" "}
              <a href="https://support.apple.com/en-us/HT202074">turning off personalized ads</a>). Mobile app users may
              also download through the applicable app store the AppChoices app to learn about how you can opt-out of
              the collection of cross-app data on this device for targeted advertising by participating companies. Since
              not all of the partners we work with will be available in AppChoices, you should exercise your choices
              using both mobile device settings and AppChoices. If you opt-out, you will continue to receive the same
              number of mobile ads, but they may be less relevant because they will not be based on your interests. You
              may still see ads related to the content on a web page or in an application or based on other non-personal
              information. Please see “How can you control ClassDojo’s use of Online Tracking Technologies to show you
              ads?” for more information.
            </p>
          </li>
        </ul>
        <hr />
        <h2 id="why-do-we-use-online-tracking-technologies">Why do we use Online Tracking Technologies?</h2>
        <p>
          ClassDojo uses cookies that are session-based (temporary) and persistent-based (permanent). Session cookies
          exist only during one session. They disappear from your computer when you close your browser software or turn
          off your computer. Persistent cookies remain on your computer or mobile device even after you close your
          browser or turn off your computer and make it possible to recognize you during subsequent visits to the
          website or application. Persistent cookies remain on your device until their end date is reached until a new
          cookie is installed or until you delete them in the settings of your browser, application, or mobile device.
        </p>
        <p>
          We use these technologies for a variety of reasons, such as allowing us to show you content that’s most
          relevant to you, improving our products and services, and helping to keep our Service and the ClassDojo
          Website secure. Please visit{" "}
          <a href="https://www.classdojo.com/cookies-policy/?oneTrust=true#FullListCookies">
            <strong>here</strong>
          </a>{" "}
          to see the list of Online Tracking Technologies used on ClassDojo.com. Please note that this list is not
          updated daily. While specific names of the Online Tracking Technologies that we use may change from time to
          time as we improve and update our Service or the ClassDojo Website, they generally fall into the below
          categories of use:
        </p>
        <table>
          <thead>
            <tr>
              <th>Categories of use</th>
              <th>Description</th>
              <th>Manage Settings</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <strong>Strictly Necessary</strong> - Authentication
              </td>
              <td>
                These cookies are necessary for the website to function and cannot be switched off in our systems. These
                tell us when you’re logged in, so we can show you the appropriate experience and features.{" "}
                <em>For example</em>, cookies help us remember your browser so you do not have to keep logging into our
                ClassDojo Website or Services.
              </td>
              <td>Please see above</td>
            </tr>
            <tr>
              <td>
                <strong>Strictly Necessary</strong> – Security and site integrity
              </td>
              <td>
                These cookies are necessary for the website to function and cannot be switched off in our systems. These
                help keep ClassDojo safe and secure by supporting and enabling security features and help us detect
                activity that violates our Terms of Service or otherwise degrades our ability to provide our ClassDojo
                Website and Services. <em>For example</em>: We use security cookies to authenticate users, prevent
                fraudulent use of login credentials, protect user data from unauthorized parties, and help us fight spam
                and phishing attacks.
              </td>
              <td>Please see above</td>
            </tr>
            <tr>
              <td>
                <strong>Functional</strong> – Localization
              </td>
              <td>
                These help us provide localized experiences. <em>For example</em>: We may store information in a cookie
                that is placed on your browser or device so you will see the ClassDojo Website or Services in your
                preferred language.
              </td>
              <td>Please see above</td>
            </tr>
            <tr>
              <td>
                <strong>Functional</strong> – Features and services
              </td>
              <td>
                These provide functionality that helps us deliver products and services and change the way the site
                behaves or looks. <em>For example:</em> Cookies help us store preferences, know when you’ve seen or
                interacted with certain content, and provide you with customized content and experiences. For instance,
                cookies allow us to make suggestions to you and others, and to customize content on our Services or the
                ClassDojo Website. Loss of the information stored in a preference cookie may make the ClassDojo Website
                or Services experience less functional but should not prevent it from working.
              </td>
              <td>Please see above</td>
            </tr>
            <tr>
              <td>
                <strong>Performance</strong>
              </td>
              <td>
                These help us ensure ClassDojo is operationally performing at a high level, such as by understanding how
                quickly our Service or the ClassDojo Website loads for different users. <em>For example</em>: these
                cookies help us route traffic between servers and understand how quickly certain pages load for
                different people and if they receive error messages from certain pages. These cookies do not collect
                information that individually identifies a visitor. These are used to improve how the Service and
                ClassDojo Website functions and performs.
              </td>
              <td>Please see above</td>
            </tr>
            <tr>
              <td>
                <strong>Performance -</strong> Analytics and research
              </td>
              <td>
                From time to time, ClassDojo engages third parties to track and analyze usage and volume statistical
                information from individuals who visit our ClassDojo Websites and Services. ClassDojo may also utilize
                Flash cookies for these purposes. These are used to understand how our Services and ClassDojo Website
                visitors use and engage with ClassDojo, and thus so we can continue to find ways to make ClassDojo a
                great experience. <em>For example</em>: We may use these technologies to know which features on
                ClassDojo are most popular - and which ones could be improved.
              </td>
              <td>Please see above</td>
            </tr>
            <tr>
              <td>
                <strong>Advertising</strong>
              </td>
              <td>
                We use cookies on the ClassDojo Websites to make advertising more engaging to visitors and to retarget
                ClassDojo visitors on other sites (such as Facebook). Some common applications of cookies are to select
                advertising based on what’s relevant to a visitor; to improve reporting on campaign performance, and to
                avoid showing ads the visitor has already seen.{" "}
                <strong>
                  We do not use{" "}
                  <a href="https://help.classdojo.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms">
                    advertising cookies
                  </a>{" "}
                  or similar technologies in areas where students or children are logged in to the Services or on the
                  ClassDojo Website where students or children are directed to login in order to access the Services on
                  a dedicated child or student login page.
                </strong>{" "}
                <em>For example:</em> ClassDojo uses cookies delivered by third parties to show you ads for ClassDojo
                products and services that we think may interest you on this and other devices you may use, and to track
                the performance of ClassDojo advertisements. For example, these cookies remember which browsers have
                visited the ClassDojo Website. We may also work with third-party advertising companies that collect
                information about your visits to non-affiliated mobile applications in order to show you advertisements
                that may interest you on devices you may use. Please note that the choices described above regarding
                browser-based targeted advertising may not apply to targeted advertising involving mobile applications.
                Your mobile device operating system may offer settings that enable you to make choices about the
                collection of mobile app information for targeted advertising.
              </td>
              <td>
                You can opt-out of seeing online interest-based (or “targeted”) ads from ClassDojo and other
                participating companies through the{" "}
                <a href="https://optout.aboutads.info/?c=2&amp;lang=EN">Digital Advertising Alliance</a> or the{" "}
                <a href="https://optout.networkadvertising.org/?c=1">Network Advertising Initiative</a> in the US, the{" "}
                <a href="https://youradchoices.ca/">Digital Advertising Alliance of Canada</a> in Canada, or the{" "}
                <a href="http://www.youronlinechoices.eu/">European Interactive Digital Advertising Alliance</a> in
                Europe or through the ClassDojo Cookie Preference Center or your mobile device settings as mentioned
                above.
              </td>
            </tr>
          </tbody>
        </table>
        <hr />
        <h2 id="how-do-third-parties-use-online-tracking-technologies-on-our-service-and-the-classdojo-website">
          How do third parties use Online Tracking Technologies on our Service and the ClassDojo Website?
        </h2>
        <p>
          In addition to our own Online Tracking Technologies, we work with various companies to help us analyze how our
          Service and the ClassDojo Website are used, for functionality and serving content, for advertising, and to
          improve ClassDojo and deliver the best possible experience for teachers, families, and students.{" "}
        </p>
        <p>
          These third parties may use web measurement and customization technologies (such as cookies) in conjunction
          with the provision of these services. Take a look at our{" "}
          <a href="https://www.classdojo.com/third-party-service-providers/">
            <strong>third-party service providers</strong>
          </a>{" "}
          to learn more about them as well as to review their privacy policies. Additionally, please visit{" "}
          <a href="https://www.classdojo.com/cookies-policy/#FullListCookies">here</a> to see the list of Online
          Tracking Technologies used on ClassDojo.com. Please note that this list is not updated daily.{" "}
        </p>
        <p>
          Third parties may also use cookies on their own website and applications in connection with the ClassDojo
          Website or Services, such as social media sharing or authentication features from Facebook and Twitter.
        </p>
        <hr />
        <h2 id="how-can-you-control-classdojo-s-use-of-online-tracking-technologies-to-show-you-ads">
          How can you control ClassDojo’s use of Online Tracking Technologies to show you ads?
        </h2>
        <p>
          <strong>
            <em>
              We do not use{" "}
              <a href="https://help.classdojo.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms">
                advertising cookies
              </a>{" "}
              or similar technologies in areas where students or children are logged in to the Services or to the
              CLassDojo Website where students or children are directed to login in order to access the Services on a
              dedicated child or student login page.
            </em>
          </strong>{" "}
          We may partner with a third party to either display advertising on the ClassDojo Website or manage our
          advertising on other sites. Our third-party partner may use{" "}
          <a href="https://classdojo.zendesk.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_3cee3e5e-c072-4fc2-821d-84258d56c2c4">
            cookies or similar technologies
          </a>{" "}
          in order to provide you with advertising based on your browsing activities and interests. You can opt-out of
          seeing online interest-based (or “targeted”) ads from ClassDojo and other participating companies through the{" "}
          <a href="https://optout.aboutads.info/?c=2&amp;lang=EN">Digital Advertising Alliance</a> or the{" "}
          <a href="https://optout.networkadvertising.org/?c=1">Network Advertising Initiative</a> in the US, the{" "}
          <a href="https://youradchoices.ca/">Digital Advertising Alliance of Canada</a> in Canada, or the{" "}
          <a href="http://www.youronlinechoices.eu/">European Interactive Digital Advertising Alliance</a> in Europe.
          Some of our advertising-related activities may involve the services of companies that do not participate in
          those programs. For example, we may use the Google AdWords remarketing service to advertise on third-party
          websites (including Google) to previous visitors to our ClassDojo Website. You can set preferences for how
          Google advertises to you using the{" "}
          <a href="http://www.google.com/settings/ads/onweb/">Google Ad Preferences page</a>. You will also have certain
          options for management of Online Tracking Technologies relating to advertising as set forth in the applicable
          jurisdiction-specific ClassDojo Cookie Settings Preference Center.
        </p>
        <p>
          The opt-outs apply only to the browser in which you set them. For example, if you set the opt-out while using
          Firefox, but then use Chrome, the opt-out will not be active in Chrome. To opt-out in Chrome, you will need to
          repeat the opt-out process. Please note you may continue to receive generic ads even after opting out.{" "}
        </p>
        <p>
          We may also work with third-party advertising companies that collect information about your visits to
          non-affiliated mobile applications in order to show you advertisements that may interest you on devices you
          may use. Please note that the choices described above regarding browser-based targeted advertising may not
          apply to targeted advertising involving mobile applications. Your mobile device operating system may offer
          settings that enable you to make choices about the collection of mobile app information for targeted
          advertising (for example, by re-setting your device’s advertising identifier and/or such as{" "}
          <a href="https://support.apple.com/en-us/HT202074">turning off personalized ads</a>). Mobile app users may
          also download through the applicable app store the AppChoices app to learn about how you can opt-out of the
          collection of cross-app data on this device for targeted advertising by participating companies. Since not all
          of the partners we work with will be available in AppChoices, you should exercise your choices using both
          mobile device settings and AppChoices.{" "}
        </p>
        <hr />
        <h2 id="online-tracking-technologies-from-unregistered-users">
          Online Tracking Technologies from unregistered users
        </h2>
        <p>
          We still use Online Tracking Technologies if you don’t have an account or have logged out of your account. For
          example, if you have logged out of your account we use cookies to, for example, help you log in faster the
          next time.
        </p>
        <p>
          We also set cookies if you don’t have a ClassDojo account, but have visited parts of classdojo.com, to help us
          protect the ClassDojo Website and the Service, and the people who use it from malicious activity. For example,
          these cookies help us detect and prevent denial-of-service attacks and the mass creation of fake accounts. If
          you have cookies on your browser or device, we read that cookie when you visit a site with our app.
        </p>
        <p>
          <strong>
            We do not use{" "}
            <a href="https://help.classdojo.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms">
              advertising cookies
            </a>{" "}
            or similar technologies in areas where students or children are logged in to the Services, or on the
            ClassDojo Website where a student or child user is directed to login to the dedicated child or student login
            page.
          </strong>{" "}
        </p>
        <hr />
        <h2 id="detailed-list-of-online-tracking-technologies-in-the-services-and-classdojo-website">
          Detailed list of Online Tracking Technologies in the Services and ClassDojo Website{" "}
        </h2>

        <div className="collapse legal-body" id="FullListCookies">
          <OneTrustCookieTable />
        </div>
      </div>
    </div>
  );
};

const content = {
  menu: `<ul>
<li><a href="#what-are-some-of-these-online-tracking-technologies">What are some of these Online Tracking Technologies?</a></li>
<li><a href="#how-do-we-use-online-tracking-technologies-when-you-use-a-mobile-device">How do we use Online Tracking Technologies when you use a mobile device?</a></li>
<li><a href="#how-can-i-manage-these-online-tracking-technologies">How can I manage these Online Tracking Technologies?</a></li>
<li><a href="#why-do-we-use-online-tracking-technologies">Why do we use Online Tracking Technologies?</a></li>
<li><a href="#how-do-third-parties-use-online-tracking-technologies-on-our-service-and-the-classdojo-website">How do third parties use Online Tracking Technologies on our Service and the ClassDojo Website?</a></li>
<li><a href="#how-can-you-control-classdojo’s-use-of-online-tracking-technologies-to-show-you-ads">How can you control ClassDojo’s use of Online Tracking Technologies to show you ads?</a></li>
<li><a href="#online-tracking-technologies-from-unregistered-users">Online Tracking Technologies from unregistered users</a></li>
<li><a href="#detailed-list-of-online-tracking-technologies-in-the-services-and-classdojo-website">Detailed list of Online Tracking Technologies in the Services and ClassDojo Website</a></li>
</ul>`,
  componentContent: <CookiesPolicyContent />,
};

function CookiesPolicy() {
  return (
    <DocumentationPageLayout
      content={content}
      title="Cookies Policy"
      seoTitle="page_titles.cookies"
      seoDescription="page_descriptions.cookies"
      type="cookies"
    />
  );
}

export default CookiesPolicy;
